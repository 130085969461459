import React, { useState } from "react";
import { Element } from 'react-scroll';
import Select from 'react-select';

import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

const stateOptions = [
  { value: 'NY', label: 'NY' },
  { value: 'CA', label: 'CA' },
  { value: 'Others', label: 'Others(for online events and travel partners only)' },
];

const ageOptions = [
  { value: '18-20', label: '18-20' },
  { value: '21-25', label: '21-25' },
  { value: '26-30', label: '26-30' },
  { value: '30+', label: '30+' },
];

export default function Contact() {
  const [formData, setFormData] = useState({
    email: "",
    city: "",
    gender: "",
    age: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeState = (selectedOption) => {
    setFormData({ ...formData, city: selectedOption.value });
  };

  const handleChangeAge = (selectedOption) => {
    setFormData({ ...formData, age: selectedOption.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email has valid format
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Check if any input value is empty or null
    const isAnyInputEmpty = Object.values(formData).some(
      (value) => value === "" || value === null
    );

    if (isAnyInputEmpty) {
      alert("Please fill in all the fields.");
      return;
    }

    fetch("https://syncso.com:8080/api/v1/waitlist/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API response:", data);
        // Handle the API response here

        alert("Form submitted successfully!");

        // Clear the form inputs
        setFormData({
          email: "",
          city: "",
          gender: "",
          age: "",
        });
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the API error here
        alert("An error occurred while submitting the form. Please try again. If the problem persists, please contact our support team.")
      });
  };

  return (
    <Element name="contact" >
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font55 extraBold">
              Get exclusive early access to our cool app!
            </h1>
            <p className="font30">
              500+ have joined our community and started making friends!
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Form onSubmit={handleSubmit}>
                <label className="font13">Email:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                  value={formData.email}
                  onChange={handleChange}
                />
                {/* <label className="font13">City:</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  className="font20 extraBold"
                  value={formData.city}
                  onChange={handleChange}
                /> */}
                <label className="font13">State:</label>
                <Select
                  id="city"
                  name="city"
                  placeholder={formData.city}
                  value={formData.city}
                  onChange={handleChangeState}
                  options={stateOptions}
                />
               
                <label className="font13">Gender:</label>
                <input
                  type="text"
                  id="gender"
                  name="gender"
                  className="font20 extraBold"
                  value={formData.gender}
                  onChange={handleChange}
                />
                {/* <label className="font13">Age:</label>
                <input
                  type="text"
                  id="age"
                  name="age"
                  className="font20 extraBold"
                  value={formData.age}
                  onChange={handleChange}
                /> */}
                <label className="font13">Age:</label>
                <Select
                  id="age"
                  name="age"
                  placeholder={formData.age}
                  value={formData.age}
                  onChange={handleChangeAge}
                  options={ageOptions}
                />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput
                  type="submit"
                  value="Submit"
                  className="pointer animate radius8"
                  style={{ maxWidth: "220px" }}
                  onClick={handleSubmit}
                />
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
    </Element>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 50px 30px 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 150px 30px 50px;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #9aff76;
  background-color: #9aff76;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #000000;
  :hover {
    background-color: #9aff76;
    border: 1px solid #9aff76;
    color: #000000;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  padding-left: 50px;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
