import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#9AFF76")};
  background-color: ${(props) => (props.border ? "transparent" : "#9AFF76")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#000000")};
  // :hover {
  //   background-color: ${(props) =>
    props.border ? "transparent" : "#580cd2"};
  //   border: 1px solid #7620ff;
  //   color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  // }
`;
